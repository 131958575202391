<template>

<b-card>

    <add-new
      :is-add-new-user-sidebar-active.sync="isAddNewSidebarActive" :companyType="companyType" :pageTitle="pageTitle"   :dataSlider="sidebarData"  
    />

    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
     <b-button
        @click="addNewData"
        variant="outline-primary"
        class="mb-2"
        style="width: 200px;"
      >
        Add {{ pageTitle}}
      </b-button>
      

      <b-table responsive="sm" :items="items" :fields="tableColumns">

      <template #cell(id)="data">
          {{data.index + 1}}
      </template>


      <template #cell(status)="data">
        <b-badge @click="changeStatus(data.item.id, data.index)" :variant="status[1][data.value]" style="cursor: pointer;">
          {{ status[0][data.value] }}
        </b-badge>
      </template>

      <template #cell(created_at)="data" >
          {{data.value | moment('YY-MM-DD HH:mm:ss')}}
      </template>

      <template #cell(brand_image)="data">
        <b-avatar :src="data.value" />
      </template>

      <template #cell(brand_icon)="data">
        <b-avatar :src="data.value" />
      </template>


      <template #cell(status)="data">
        <b-badge @click="changeStatus(data.item.id, data.item.status)" :variant="status[1][data.value]" style="cursor: pointer;">
          {{ status[0][data.value] }}
        </b-badge>
      </template>

      

      <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editData(data.item)" >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
    

    </b-overlay>
  </b-card>
</template>


<script>
import { BTable, BCard, BCardText,BAvatar, BOverlay, BBadge, BFormSelect, BDropdownItem, BDropdown, BButton } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddNew from './AddNew.vue'
// import companyData from './companyData'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { onMounted } from 'vue'
import DataService  from './data/services'
export default {
  components: {
    BTable,
    BCard,
    BCardText,
    BAvatar,
    BOverlay,
    BBadge,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    AddNew,
    BButton,
    ToastificationContent,
  },
  data() {
    return {
      items: [],
      sidebarData: {},
      isAddNewSidebarActive: false,
      isLoading: true,
      companyType: [],
      pageTitle: "Specialization",
      tableColumns: [
        { key: 'id', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'created_by', label: 'Created by', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'created_at', label: 'Created At', sortable: true },
        { key: 'actions' },
      ],
      status: [
        {
          1: 'Actvie', 0: 'Inactive',
        },
        {
          1: 'light-success', 0: 'light-danger', 
        }
      ],
      
    }
  },

    
  methods: {
    getData(){
      this.isLoading = true;
      DataService.getAll()
      .then(response => {
        this.isLoading = false
        this.items = response.data.data
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
        })
    },
    changeStatus(id, status){
      
      this.isLoading = true;
      DataService.update(id, {status: !status})
      .then(response => {
        this.isLoading = false
        this.items = response.data.data
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
      })
    },
    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    editData(data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    toggleDataSidebar(val=false) {
      this.isAddNewSidebarActive = val
    },
    refreshData(newData){
      this.items = newData
    }
  },
  mounted() {
    this.getData()
  },
  
}
</script>