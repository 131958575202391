import axios from '@/libs/axios'

class DataService {
  getAll() {
    return axios.get("/specializations");
  }
  get(id) {
    return axios.get(`/specializations/${id}`);
  }
  create(data) {
    return axios.post("/specializations", data);
  }
  update(id, data) {
    return axios.put(`/specializations/${id}`, data);
  }
}
export default new DataService();